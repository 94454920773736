.two-things-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1252px;
  margin-bottom: 64px;
}

.one-side-thing-div {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 48%;
  height: auto;
}

.mt-32 {
  margin-top: 32px;
}

.mb-64 {
  margin-bottom: 64px;
}

.one-side-img {
  display: flex;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.o_nama_column_paragraph {
  color: white;
  max-width: 848px;
  font-size: 18px;
  font-weight: 400;
  min-width: 848px;
  text-align: center;
  margin-bottom: 32px;
}

.treneri-div {
  display: flex;
  width: 1252px;
  flex-wrap: wrap;
}

.trener-card {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgba(224, 181, 0, 1) 0%,
    rgba(224, 173, 0, 0.85) 15%,
    rgba(230, 187, 0, 0.7) 30%,
    rgba(235, 191, 0, 0.5) 50%,
    rgba(241, 194, 0, 0.35) 65%,
    rgba(246, 194, 0, 0.2) 80%,
    rgba(252, 224, 0, 0.1) 90%,
    rgba(255, 255, 0, 0) 100%
  );
  width: 298px;
  height: 525px;
  margin: 0px 6px;
  margin-top: 84px;
  margin-bottom: 42px;
}

.trener-card-img {
  position: absolute;
  width: 100%;
  height: auto;
  top: -110px;
}

.trener-card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  top: 262px;
  position: absolute;
}

.trener-card-h {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  color: white;
  margin-top: 12px;
}

.trener-card-p {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  color: white;
  text-align: center;
}

.mt-12 {
  margin-top: 12px;
  max-width: 95%;
}

.uprava-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 404px;
  height: auto;
  background-color: var(--gold);
  border-radius: 15px;
  overflow: hidden;
  padding-bottom: 12px;
  margin: 6px;
  max-width: 95%;
}

.uprava-img {
  width: 404px;
  height: 404px;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 1252px) {
  .two-things-div {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }

  .treneri-div {
    display: flex;
    width: calc(100% - 32px);
    justify-content: center;
    padding: 0px 16px;
    flex-wrap: wrap;
  }

  .trener-card {
    width: 298px;
    height: 525px;
  }

  .trener-card-content {
    top: 262px;
  }
}

@media only screen and (max-width: 848px) {
  .o_nama_column_paragraph {
    max-width: calc(100% - 32px);
    min-width: calc(100% - 32px);
    padding: 0px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .two-things-div {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .one-side-thing-div {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }
}

@media only screen and (max-width: 460px) {
  .treneri-div {
    display: flex;
    width: calc(100% - 32px);
    justify-content: center;
    padding: 0px 16px;
    flex-wrap: wrap;
  }

  .trener-card {
    width: 298px;
    height: 525px;
  }

  .trener-card-content {
    top: 262px;
  }
}
