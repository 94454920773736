.novost-content-div {
  display: flex;
  flex-direction: column;
  width: 1252px;
  align-items: center;
  justify-content: start;
}

.novost-content-img mt-32 {
  display: flex;
  width: 100%;
  height: 507px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  margin-top: 64px;
}

.novost-content-heading-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 64px;
}

.novost-content {
  display: flex;
  flex-direction: column;
  width: 827px;
}

.novost-content-heading {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  color: white;
}

.text-align-center {
  text-align: center;
}

.novost-content-date-p {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: white;
  margin-top: 16px;
}

.mt-16 {
  margin-top: 16px;
}

/* Novost content */
.novost-content-body {
  font-family: "Poppins", sans-serif;
  color: white !important;
}
.novost-content-body h1,
.novost-content-body h2 {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  color: white;
}

.novost-content-body p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: white !important;
}

.novost-content-body img {
  display: flex;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  margin-bottom: 32px;
}
.novost-content-body ul,
.novost-content-body ol {
  padding-left: 20px;
  margin-bottom: 1em;
}

.loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 1252px) {
  .novost-content-div {
    padding: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 827px) {
  .novost-content {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .novost-content-body p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .novost-content-heading-div {
    margin-top: 16px;
    margin-bottom: 32px;
  }

  .novost-content-body h1,
  .novost-content-body h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
  }
}
