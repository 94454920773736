.rezultati-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.rezultati-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 1252px;
  height: auto;
}

.rezultati-h {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  color: white;
  background-color: var(--gold);
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  margin: 16px 0px;
}

.rezultati-p {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: white;
  margin: 4px 0px;
}

@media only screen and (max-width: 1252px) {
  .rezultati-content {
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 600px) {
  .rezultati-h {
    width: calc(100% - 32px);
  }

  .rezultati-p {
    font-size: 14px;
    font-weight: 600;
  }
}
