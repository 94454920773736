.footer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--gold);
  margin-top: 120px;
  padding-top: 64px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 1252px;
}

.footer-links-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-nav-link {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: var(--black);
  margin-right: 20px;
  text-decoration: none;
}

.footer-nav-link:hover {
  text-decoration: underline;
}

.footer-socail-img {
  width: 36px;
  height: 36px;
  cursor: pointer;
  margin-left: 16px;
}

.footer-line {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--black);
  margin: 16px 0px;
}

.footer-logos-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  margin-bottom: 16px;
}

.footer-iskra-div {
  display: flex;
  align-items: center;
}

.footer-iskra-logo {
  width: 61px;
  height: 77px;
  margin-right: 32px;
}

.footer-iskra-p {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: var(--black);
}

.footer-visure-logo {
  width: 185px;
  height: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 1252px) {
  .footer-content {
    padding: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 725px) {
  .footer-links-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
  }

  .footer-nav-link {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .footer-social-links {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .footer-socail-img {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 505px) {
  .footer-logos-div {
    flex-direction: column;
    justify-content: center;
  }

  .footer-iskra-div {
    flex-direction: column;
    align-items: center;
  }

  .footer-iskra-logo {
    margin-right: 0px;
    margin-bottom: 8px;
  }

  .footer-visure-logo {
    margin-top: 16px;
  }
}
