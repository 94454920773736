.link {
  display: flex;
  width: 100%;
  text-decoration: none;
  color: var(--black);
}

.page-container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
}

.hero-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1252px;
  margin-top: calc(64px + 100px);
}

.hero-img {
  width: 649px;
  height: 687px;
}

.hero-section-right-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 50%;
}

.hero-section-heading {
  color: var(--gold);
  font-family: "KarateFont";
  font-size: 48px;
  font-weight: bold;
}

.hero-paragraph {
  color: white;
  max-width: 618px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 32px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--gold);
  padding: 8px 32px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.hero-section-left-div {
  display: flex;
  width: 50%;
  justify-content: center;
}

.column-section {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1252px;
  margin-top: 64px;
  align-items: center;
  justify-content: start;
}

.paragraph {
  color: white;
  font-size: 18px;
  font-weight: 400;
  max-width: 900px;
  text-align: center;
}

.middle-hero-img {
  width: 834px;
  margin-bottom: 32px;
}

.second-hero-section-left-div {
  display: flex;
  width: 50%;
  justify-content: start;
}

.second-hero-img {
  width: 471;
  height: 670px;
}

.three-items-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 48px;
}

.program-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(400px - 48px);
  height: auto;
  border-radius: 15px;
  padding: 0px 24px 24px 24px;
  background: linear-gradient(
    to bottom,
    rgba(224, 181, 0, 1) 0%,
    rgba(224, 173, 0, 0.85) 15%,
    rgba(230, 187, 0, 0.7) 30%,
    rgba(235, 191, 0, 0.5) 50%,
    rgba(241, 194, 0, 0.35) 65%,
    rgba(246, 194, 0, 0.2) 80%,
    rgba(252, 224, 0, 0.1) 90%,
    rgba(255, 255, 0, 0) 100%
  );
}

.program-card-img {
  width: 359px;
  height: 505px;
  position: absolute;
  top: -85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mt-48 {
  margin-top: 48px;
}

.program-card-content {
  padding-top: 430px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.program-card-h {
  color: white;
  font-size: 32px;
  font-weight: 400;
}

.program-card-p {
  font-size: 16px;
  color: white;
  font-weight: 400;
  margin: 16px 0px;
}

.program-card-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  padding: 8px 0px;
  width: 100%;
}

.blog-card {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid var(--gold);
  width: calc(400px - 48px);
  padding: 24px;
}

.blog-card-img {
  width: 360px;
  height: 204px;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
}

.blog-card-date {
  color: white;
  font-size: 14px;
  font-weight: 300;
  margin: 12px 0px;
}

.blog-card-heading {
  color: white;
  font-size: 24px;
  font-weight: 400;
}

.blog-card-text {
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 300;
  margin-top: 12px;
  margin-bottom: 24px;
}

.blog-card-btn-div {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.blog-card-btn-p {
  color: white;
  font-size: 14px;
  font-weight: 400;
}

.blog-card-btn-img {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.contact-div {
  display: flex;
  width: 819px;
  height: 450px;
  background-color: white;
  border-radius: 15px;
  margin-top: 48px;
}

.contact-div-form-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 0.5px);
  padding: 54px 0px;
}

.contact-div-form {
  display: flex;
  flex-direction: column;
  width: 360px;
  align-items: center;
}

.contact-div-img {
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
}

.contact-input {
  display: flex;
  padding: 8px 16px;
  width: calc(100% - 32px);
  border: 1px solid var(--gray);
  border-radius: 5px;
  color: var(--gray);
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  margin-bottom: 16px;
}

.contact-textarea {
  display: flex;
  padding: 8px 16px;
  min-width: calc(100% - 32px);
  max-width: calc(100% - 32px);
  min-height: 120px;
  max-height: 120px;
  color: var(--gray);
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  border: 1px solid var(--gray);
  border-radius: 10px;
}

.contact-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
  cursor: pointer;
  border-radius: 10px;
  background-color: var(--black);
  color: white;
  margin-top: 16px;
}

.contact-div-line {
  width: 1px;
  height: 100%;
  background-color: var(--gray);
}

.contact-info-div {
  display: flex;
  padding: 0px 20px;
  width: calc(50% - 40px);
  flex-direction: column;
  justify-content: center;
}

.contact-info-h {
  font-size: 24px;
  color: var(--black);
  font-weight: 400;
  max-width: 277px;
}

.contact-info-bar {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.contact-info-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.contact-info-p {
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
}

.error-message {
  color: #f14336;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  text-align: start;
}

@media only screen and (max-width: 1252px) {
  .hero-section {
    display: flex;
    justify-content: space-between;
    width: 95%;
    max-width: 1252px;
  }

  .hero-section-right-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 50%;
  }

  .three-items-div {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 48px;
    flex-wrap: wrap;
  }

  .program-card {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 82px;
  }

  .blog-card {
    max-width: 90%;
    margin: 8px;
  }

  .blog-card-img {
    max-width: 100%;
    height: auto;
  }

  .mb-85 {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 865px) {
  .contact-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 95%;
    height: auto;
    width: auto;
  }

  .contact-div-form-div {
    width: calc(100% - 0.5px);
    padding: 30px 0px;
  }

  .contact-div-form {
    width: calc(100% - 32px);
  }

  .contact-div-line {
    width: 100%;
    height: 1px;
  }

  .contact-info-div {
    padding: 30px 20px;
    width: calc(100% - 40px);
  }

  .contact-input {
    max-width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 976px) {
  .hero-section {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    max-width: none;
  }

  .hero-section-right-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 95%;
    order: 1;
  }

  .hero-section-left-div {
    display: flex;
    width: 95%;
    justify-content: center;
  }

  .hero-img {
    width: 473px;
    height: auto;
    max-width: 90%;
  }

  .hero-section-heading {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }

  .hero-paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .column-section {
    max-width: 95%;
  }

  .paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }

  .middle-hero-img {
    width: 834px;
    max-width: 95%;
    margin-bottom: 32px;
  }

  .second-hero-section-left-div {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .second-hero-img {
    height: auto;
    max-width: 90%;
    max-height: 397px;
  }

  .program-card {
    width: calc(100% - 48px);
    max-width: 90%;
    height: auto;
    margin-top: 82px;
  }
}
