.blog-page-content {
  display: flex;
  flex-direction: column;
  width: 1252px;
}

.blog-heading-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 32px 0px;
}

.search-input-div {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 16px;
  width: calc(311px - 32px);
  height: calc(40px - 16px);
  background-color: white;
  border: 1px solid var(--gray3);
  border-radius: 10px;
}

.search-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.search-input {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border: none;
}

.search-input:focus {
  outline: none;
}

.blogs-div {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
  flex-wrap: wrap;
}

.novost-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  border: 1px solid var(--gold);
  width: calc(400px - 48px);
  padding: 24px;
  margin: 7px 7px;
}

@media only screen and (max-width: 1252px) {
  .blog-page-content {
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 500px) {
  .blog-heading-div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .search-input-div {
    width: calc(100% - 32px);
  }
}
