@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "KarateFont";
  src: url("./assets/karate-font/KarateLight-BVMl.ttf") format("truetype");
  /* Add another src line for the bold font variation */
  src: url("./assets/karate-font/KarateBold-1Vx4.ttf") format("truetype");
  /* Add additional src lines for different font formats (woff, woff2, etc.) */
  font-weight: normal;
  font-style: normal;
}
@import "animate.css";

:root {
  --gold: #dbad00;
  --black: #212121;
  --gray: #808080;
}

body,
div {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

/* Hide scrollbars for Webkit browsers (Chrome, Safari) */
body::-webkit-scrollbar,
div::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: var(--black);
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
  padding: 0px;
}
