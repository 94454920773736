.header-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  position: fixed;
  z-index: 100000;
}

.mt-100 {
  margin-top: calc(100px + 32px);
}

.info-line {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 30px;
  background-color: var(--gold);
  padding: 0px 30px;
  width: calc(100% - 60px); /* Use 100% to span the full width */
  overflow: hidden;
}

.info-content {
  display: flex;
  flex-wrap: nowrap;
  width: 200%;
  justify-content: end;
}

.info-property-div {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 15px;
  white-space: nowrap; /* Prevent text from wrapping */
}

.info-line-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.header-div {
  display: flex;
  width: calc(100% - 60px);
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: white;
  padding: 0px 30px;
}

.header-admin-icon {
  width: 24px;
  height: 25px;
  cursor: pointer;
}

.header-social-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 20px;
}

.header-logo {
  width: 52px;
  height: 61px;
  margin: 0px 10px;
}

.links-div {
  display: flex;
  align-items: center;
}

.header-link {
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
  margin: 0px 10px;
  font-weight: 600;
  font-size: 16px;
}

.header-link:hover {
  text-decoration: underline;
}

.header-mobile-div {
  display: none;
}

.header-hamburger-div {
  display: none;
}

.header-help-div {
  display: flex;
  width: 90px;
}

.header-admin-div {
  display: flex;
  width: 90px;
  justify-content: end;
}

@media only screen and (max-width: 880px) {
  .header-div {
    width: calc(100% - 32px);
    padding: 0px 16px;
  }

  .header-link {
    display: none;
  }

  .header-social-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .header-admin-div {
    display: none;
  }

  .header-mobile-div {
    position: absolute;
    top: 100px;
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    width: calc(100% - 32px);
    height: 0px;
    background-color: white;
    overflow: hidden;
  }

  .header-mobile-div-open {
    height: auto;
    padding: 24px 16px;
    transition: all 0.2s ease-in-out;
    z-index: 10;
  }

  .header-mobile-link {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 10px;
    color: var(--black);
  }

  .header-hamburger-div {
    display: flex;
    width: 50px;
  }

  .header-help-div {
    display: flex;
    width: 50px;
  }

  .info-content {
    display: flex;
    flex-wrap: nowrap;
    animation: slide 10s linear infinite;
    width: 200%;
  }
}
