.hero-paragraph-subheading {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  color: white;
}

.mb-32 {
  margin-bottom: 32px;
}

.aktivnost-img {
  width: 100%;
  height: 429px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.questions-div {
  display: flex;
  flex-direction: column;
  width: 1252px;
  margin-bottom: 64px;
}

.question-tab {
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  width: calc(100% - 32px);
  height: 60px;
  background-color: var(--gold);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;
}

.question-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
}

.question-h {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: white;
}

.question-sparkle {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.question-tab-active {
  height: auto;
}

.question-sparkle-active {
  rotate: 45deg;
}

.answer-content {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: white;
  margin-bottom: 16px;
}

.zanimljivosti-div {
  display: flex;
  flex-direction: column;
  width: 1252px;
}

.zanimljivost-tab {
  display: flex;
  width: 100%;
  align-items: center;
}

.japan-letter-img {
  width: 298px;
  height: 298px;
  margin-right: 120px;
}

@media only screen and (max-width: 1252px) {
  .questions-div {
    padding: 0px 16px;
    width: calc(100% - 32px);
  }

  .question-h {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .answer-content {
    font-size: 14px;
    font-weight: 400;
  }

  .zanimljivosti-div {
    align-items: center;
    padding: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 700px) {
  .hero-paragraph-subheading {
    font-size: 24px;
  }

  .aktivnost-img {
    height: auto;
  }

  .zanimljivost-tab {
    display: flex;

    flex-direction: column;
    width: 80%;
    align-items: center;
  }

  .japan-letter-img {
    width: 298px;
    height: 298px;
    margin-right: 0px;
    max-width: 95vw;
  }
}
